import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="border-b border-emerald-500 py-4">
      <div
        className="hover:cursor-pointer flex justify-between items-center "
        onClick={() => setIsOpen(!isOpen)}
      >
       
        <h3 className="py-0 my-0 w-3/4 text-lg font-medium text-left">{question}</h3>
 
       
        {isOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        ) : ( 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        )}
     
      </div>
      {isOpen && <div className="pt-4">{answer}</div>}
    </div>
  );
};

export default FAQItem;