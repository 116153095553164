// src/components/Sidebar.js
import React from 'react';
import { Link } from 'gatsby';
import Touch from './touch';
import i18n from '../../i18n';

const SimpleSidebar = ({ services, currentSlug, title, desc, cta, ctaLink }) => {


  console.log('Services   '+services)

  // Function to remove the language part from the slug
  const stripLanguageFromSlug = (slug) => {
    const slugParts = slug.split('/').filter(Boolean); // Remove empty parts
    if (slugParts.length > 1) {
      // Assume the first part is the language
      slugParts.shift();
    }
    return slugParts.join('/');
  };

  return (
    // <aside className="w-full lg:w-80 min-h-96">
      <nav className="w-full lg:w-80 sticky top-0 p-4 bg-white border-r overflow-y-auto">
        <ul className="pb-4">
          {services.map(({ node }) => {
          // Remove the language part from the service slug
            const serviceSlugWithoutLanguage = stripLanguageFromSlug(node.fields.slug);
            console.log(serviceSlugWithoutLanguage)
            console.log(node.fields.slug)
            console.log(currentSlug)
            return (
            <li key={node.fields.slug}>
               {currentSlug === serviceSlugWithoutLanguage && (
                <div className="flex items-center justify-start border-b">
                    {/* <div className="w-1/5"> */}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-emerald-500">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    {/* </div> */}
                    <Link
                        to={node.fields.slug}
                        className={`p-2 hover:bg-gray-100 font-semibold ${
                        currentSlug === serviceSlugWithoutLanguage ? '' : ''
                        }`}
                        activeClassName="text-slate-700"
                        >
                            {node.frontmatter.title}
                    </Link>
                </div>
               )}
              {currentSlug !== serviceSlugWithoutLanguage && (
              <Link
                to={node.fields.slug}
                className={`text-slate-700 font-semibold block p-2 hover:bg-gray-100 border-b py-2 ${
                  currentSlug === node.fields.slug ? 'bg-gray-100 font-semibold' : ''
                }`}
                activeClassName="text-slate-700"
              >
                {node.frontmatter.title}
              </Link>
              )}
            </li>
           );
          })}
        </ul>
        <Touch title={title} description={desc} cta={cta} ctaLink={ctaLink}/>
      </nav>
    // </aside>
  );
};

export default SimpleSidebar;