import React from 'react';
import { graphql } from 'gatsby'
import SiteNavigation from '../components/header/siteNavigation'
import CommonFooter from '../components/footer/commonFooter'
import Seo from '../components/seo/seo'
import Img from 'gatsby-image'
import FAQItem from '../components/common/faqItem';
import SimpleSidebar from '../components/common/simpleSidebar';
import Touch from '../components/common/touch';
import { useTranslation } from 'react-i18next'


const ServiceTemplate = ({ data }) => {

  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language

  console.log('All services:', data.allServices.edges);

  const filteredServices = data.allServices.edges.filter(({ node }) => node.frontmatter.lang === currentLanguage)
 
  console.log('Filtered services:', filteredServices);
  // Inizializzare le faq
  const faqs = [];

  // Loop through the frontmatter keys and extract faq pairs
  for (const key in data.markdownRemark.frontmatter) {
    if (key.startsWith('q') && data.markdownRemark.frontmatter[key]) {
      const index = key.substring(1); // Get the number after 'q'
      const question = data.markdownRemark.frontmatter[key];
      const answer = data.markdownRemark.frontmatter[`a${index}`];
      if (question && answer) {
        faqs.push({ question, answer });
      }
    }
  }

  return(
      <>
        <Seo
          title={data.markdownRemark.frontmatter.seoTitle}
          description={data.markdownRemark.frontmatter.seoDescription}
          seoImage={
            data.markdownRemark.frontmatter.seoImage.childImageSharp.resize.src
          }
        />
        <SiteNavigation />
        <div className='h-96 bg-gradient-to-r from-indigo-500 via-sky-500 to-emerald-500 relative'>
          <Img
          fluid={data.markdownRemark.frontmatter.seoImage.childImageSharp.fluid}
          style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
          }}
          imgStyle={{
              objectFit: 'cover', // Cover the entire div area
              objectPosition: '50% 50%', // Center the image within the div
              opacity: 0.2, // Optional: Set the opacity if you want to see anything behind it
          }}
          // className="image-wrapper-height-auto"
          alt={data.markdownRemark.frontmatter.title}
          />
          <div className='pt-8 max-w-7xl mx-auto absolute inset-0 px-16 md:px-24 xl:px-0 flex items-center'>
            <div className='md:w-2/3'>
                <h1 className="font-semibold text-white text-4xl md:text-6xl">
                    {data.markdownRemark.frontmatter.title}
                </h1>
                <p className="mt-4 text-white font-semibold">
                  {data.markdownRemark.frontmatter.description}
                </p>
            </div>
          </div>
        </div>
        <div className='flex flex-col-reverse space-y-8 md:flex-row md:space-x-8 md:space-y-0 pt-8 pb-16 md:max-w-7xl md:mx-auto px-16 xl:px-0'>
          <div className='md:w-1/3 pt-8'>
             <SimpleSidebar services={filteredServices} currentSlug={data.markdownRemark.frontmatter.translatedPath} title={data.markdownRemark.frontmatter.sidebarTouchTitle} desc={data.markdownRemark.frontmatter.sidebarTouchDesc} cta={data.markdownRemark.frontmatter.sidebarTouchCTA} ctaLink={data.markdownRemark.frontmatter.sidebarTouchCTALink}/>
          </div>
          <div className='md:w-2/3'>
            <div className='prose max-w-none'>
              <div
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />
              {/* FAQ Sections */}
              <div className="bg-emerald-300/25 p-8 rounded">
                {faqs.map((faq, index) => (
                  <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
              </div>
          
            </div>
          </div>
        </div>
        <CommonFooter />
      </>
    )
  }

export default ServiceTemplate

export const serviceQuery = graphql`
  query Service($localizedPath: String!) {
    markdownRemark(fields: { slug: { eq: $localizedPath } }) {
      html
      frontmatter {
        lang
        path
        translatedPath
        title
        description
        seoTitle
        seoDescription
        category
        q1
        a1
        q2
        a2
        q3
        a3
        q4
        a4
        sidebarTouchTitle
        sidebarTouchDesc
        sidebarTouchCTA
        sidebarTouchCTALink
        seoImage {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 600, height: 400) {
              src
            }
          }
        }
      }
    }
    allServices: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "service" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            lang
          }
        }
      }
    }
  }
`
